<template>
  <div>
    <el-dialog title="合作商发卡" :visible.sync="sendShow" width="30%">
      <el-form ref="form" label-width="100px">
        <el-form-item label="卡券类型"> {{couponPackage.name}} </el-form-item>
        <el-form-item label="所属商会"> 
          <cooperatorSelect :coopid.sync="coop_id" :condition="{type:'commerce',status:0}"></cooperatorSelect>
       </el-form-item>

        <el-form-item label="生成方式" required>
          <el-radio v-model="send_type" label="mobiles">手动填写</el-radio>
          <el-radio v-model="send_type" label="excel">导入表格</el-radio>
        </el-form-item>
        <div v-if="send_type == 'mobiles'">
          <el-form-item label="发放用户" required>
            <p>请填写要发放用户的电话</p>
            <el-tag
              :key="tag"
              v-for="tag in dynamicTags"
              closable
              :disable-transitions="false"
              @close="handleClose(tag)"
            >
              {{ tag }}
            </el-tag>
            <el-input
              class="input-new-tag"
              v-if="inputVisible"
              v-model="inputValue"
              ref="saveTagInput"
              size="small"
              @keyup.enter.native="handleInputConfirm"
              @blur="handleInputConfirm"
            >
            </el-input>
            <el-button
              v-else
              class="button-new-tag"
              size="small"
              type="success"
              @click="showInput"
              >新增电话</el-button
            >
          </el-form-item>
        </div>
        <div v-if="send_type == 'excel'">
          <el-form-item label="发放用户" required>
            <el-upload
              action=""
              :on-change="beforeUpload"
              :file-list="fileList"
              :auto-upload="false"
              :show-file-list="true"
              accept=".xls, .xlsx"
              list-type="text"
              :limit="1"
              ref="upload"
            >
              <el-button type="success" size="small">导入表格</el-button>
              <el-button
                type="text"
                @click.stop="loadTemplate"
                style="margin: 10px"
                >下载模板</el-button
              >
              <el-popover
                placement="top-start"
                title="格式说明"
                width="400"
                trigger="hover"
              >
                <div>
                  表格数据格式：
                  <br />
                  <div class="flex">
                    <div>
                      <div class="table">电话</div>
                      <div class="table">1859524*****</div>
                    </div>
                  </div>
                </div>
                <el-button type="text" slot="reference" @click.stop="empty"
                  >excel格式</el-button
                >
              </el-popover>
            </el-upload>
          </el-form-item>
        </div>
        <el-form-item label="备注">
          <el-input
            type="textarea"
            :rows="2"
            placeholder="请输入内容"
            v-model="descr"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button style="margin-left: 30px" @click="sendShow = false"
          >取消</el-button
        >
        <el-button
          v-if="send_type == 'mobiles'"
          style="margin-left: 20px"
          type="primary"
          @click="generateCoupon"
          :loading="loading"
          >确认</el-button
        >
        <el-button
          v-if="send_type == 'excel'"
          style="margin-left: 20px"
          type="primary"
          :loading="loading"
          @click="importMemberExcel"
          >确认</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import cooperatorSelect from '../../../../components/select/cooperatorSelect.vue';

export default {
  components:{
cooperatorSelect
  },
  data() {
    return {
       sendShow: false,
      dynamicTags: [],
      inputVisible: false,
      inputValue: "",
      fileData: "",
      send_type: "mobiles",
      loading: false,
      descr: "",
      couponPackage:{},
      manager: JSON.parse(localStorage.getItem("managerInfo")),
      coop_id:'',
    };
  },

  mounted() {
    
  },

  methods: {
    open(v) {
      this.couponPackage = v
      this.sendShow = true
    },
    loadTemplate() {
      let Header = [["电话"]];
      let exportArr = [
        [""],
      ];

      const headerWs = XLSX.utils.aoa_to_sheet(Header);
      const ws = XLSX.utils.sheet_add_json(headerWs, exportArr, {
        skipHeader: true,
        origin: "A2",
      });
      ws["!cols"] = [
        {
          wpx: 100,
        }
     
      ];
      /* 新建空的工作表 */
      const wb = XLSX.utils.book_new();

      // 可以自定义下载之后的sheetname
      XLSX.utils.book_append_sheet(wb, ws, "sheetName");

      /* 生成xlsx文件 */
      XLSX.writeFile(wb, "券包发放模板.xlsx");
      this.$message("导出成功，请前往下载列表查看");
    },
    upLoad(file) {
      console.log(file);
    },
    handleClose(tag) {
      this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
    },

    showInput() {
      this.inputVisible = true;
      this.$nextTick((_) => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },

    handleInputConfirm() {
      let inputValue = this.inputValue;
      if (inputValue) {
        this.dynamicTags.push(inputValue);
      }
      this.inputVisible = false;
      this.inputValue = "";
    },

    importMemberExcel() {
      if (!this.fileData) {
        this.$message("请选择要导入的数据");
        return;
      }
      let formDate = new FormData();
      formDate.append("file", this.fileData);
      formDate.append("cooperator_id", this.coop_id || 0);
        formDate.append("id", this.couponPackage.id);
      formDate.append(
        "admin_id",
       this.manager.id,
      );
      formDate.append("descr", this.descr || this.manager.name+'发放');
      this.loading = true;
      this.$axios({
        url: "/user/couponPackage/grantByExcel",
        data: formDate,
        method: "post",
        processData: false,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((res) => {
          this.$message({ message: res.data.data });
          this.sendShow = false;
          this.loading = false;
          this.fileList = [];
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    //合作商发卡
    generateCoupon() {
      if (this.dynamicTags.length == 0) {
        this.$message("请填写要发放的用户电话");
        return;
      }
      if (this.dynamicTags.length > 5) {
        this.$message("一次最多发放5个用户");
        return;
      }
    
      let data = {
        cooperator_id:this.coop_id || 0,
        id: this.couponPackage.id ,
        admin_id: JSON.parse(localStorage.getItem("managerInfo")).id,
        mobiles: this.dynamicTags.join(","),
        descr: this.descr || this.manager.name+'发放',
      };
      this.loading = true;
      this.$axios
        .post("/user/couponPackage/grantByPhones", data)
        .then((res) => {
          this.$message({ message: res.data.message });
          this.sendShow = false;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
     // 文件选择回调
    beforeUpload(file) {
      this.fileData = file.raw;
      // this.readExcel(); // 调用读取数据的方法
    },
    // 读取数据
    readExcel(e) {
      const files = this.fileData;

      const fileReader = new FileReader();
      fileReader.onload = (ev) => {
        try {
          const data = ev.target.result;
          const workbook = XLSX.read(data, {
            type: "binary",
          });
          const wsname = workbook.SheetNames[0]; //取第一张表
          const ws = XLSX.utils.sheet_to_json(workbook.Sheets[wsname]); //生成json表格内容
        } catch (e) {
          return false;
        }
      };
    },
  },
};
</script>