<template>
  <div class="container">
    <el-form :inline="true">
      <el-form-item label="状态">
        <el-select v-model="status" placeholder="状态" clearable>
          <el-option label="待领取" :value="0"></el-option>
          <el-option label="已领取" :value="1"></el-option>
           <el-option label="已过期" :value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="getList(1)">搜索</el-button>
      </el-form-item>
    </el-form>
    <auto-table
      :DataList="DataList"
      :option="Option"
      :total="Total"
      @changePage="getList"
    >
      <template #status="{ row }">
        <div>
            <el-tag type="info" size="small" v-if="row.status === 0">待领取</el-tag>
          <el-tag type="info" size="small" v-if="row.status === 2">过期</el-tag>
          <el-tag type="success" size="small" v-if="row.status === 1"
            >已领取</el-tag>
        </div>
      </template>
    </auto-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      DataList: [],
      Option: [
        { name: "用户", value: "nick_name" },
        { name: "电话", value: "mobile" },
        { name: "状态", value: "status", type: "custom" },
        { name: "到期时间", value: "activity_time"},
        { name: "发放时间", value: "create_time" },
      ],
      Total: 0,
      status: 0,
    };
  },

  mounted() {
    this.getList(1);
  },

  methods: {
    
    //获取列表
    getList(v) {
      let page = v || 1;
      this.$axios({
        url: "/user/cpGrantRecord/queryManagerListPage",
        params: {
          currentPage: page,
          pageSize: 10,
          status: this.status,
        },
      }).then((res) => {
        this.DataList = res.data.data.rows;
        this.Total = res.data.data.total;
      });
    },
  },
};
</script>

<style lang="less" scoped>
</style>