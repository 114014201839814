<template>
    <div id="consumerCoupon">
      <el-row style="background-color: #ffffff">
        <content-title :nav="nav"></content-title>
      </el-row>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="券包管理" name="0">
             <packageManage></packageManage>    
        </el-tab-pane>
        <el-tab-pane label="领取列表" name="1">
             <recevieList></recevieList>
        </el-tab-pane>
        <!-- <el-tab-pane label="发放记录" name="2">
             <sendList></sendList>
        </el-tab-pane> -->
      </el-tabs>
    </div>
  </template>
  
  <script>
import packageManage from './components/coupPackage/packageManage.vue';
import recevieList from './components/coupPackage/recevieList.vue';
import sendList from './components/coupPackage/sendList.vue';
  export default {
    components: {
    packageManage,
      recevieList,
      sendList
    },
    data() {
      return {
        nav: { firstNav: "券包管理中心", secondNav: "券包管理" },
        activeName: 0,
      }
    },
    methods: {
        handleClick() {},
    }
  };
  </script>
  
  <style lang="less">
  #consumerCoupon {
    background-color: #ffffff;
    padding: 0 20px;
    .an-box {
      display: flex;
      font-size: 14px;
      margin-top: 20px;
      margin-bottom: 30px;
      flex-wrap: wrap;
      .an-p {
        color: #ff7e00;
        border-radius: 5px;
        border: #ff7e00 1px solid;
        padding: 4px 8px;
        margin-right: 20px;
        height: 24px;
        line-height: 24px;
        @media screen and(max-width:768px) {
          margin-bottom: 20px;
        }
      }
    }
  }
  .el-tabs__item {
    font-size: 16px !important;
  }
  .cell {
    text-align: center;
  }
  </style>
  